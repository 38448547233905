<template>
  <b-container class="mt-4">
    <b-spinner v-if="loading"></b-spinner>
    <div v-else>
      <b-row>
        <b-col class="d-flex align-items-center">
          <BackButton></BackButton>
          <h5 class="ml-2">Referrals state settings for client
            <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button class="ml-3"
                    @click="save"
                    type="button"
                    :disabled="saving"
                    variant="primary">
            <b-spinner label="Loading..." variant="light" small v-if="saving"></b-spinner>
            <span v-else>Save changes</span>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <div v-for="(config, index) in referralConfig" :key="index">
            <ReferralRulesInput :type="config.type"
                                v-model="config.value"
                                :inputKey="config.key"
                                :label="config.label"
                                @change="onChange">
            </ReferralRulesInput>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import ReferralRulesInput from '../../../components/Inputs/ReferralRulesInput.vue';
import BackButton from '../../../components/BackButton.vue';

export default {
  name: 'ClientReferrals',
  components: {
    ReferralRulesInput,
    BackButton,
  },
  mixins: [ errorHandler ],
  data() {
    return {
      referralConfig: null,
      client: null,
      loading: false,
      saving: false,
    };
  },
  async beforeMount() {
    this.loading = true;

    try {
      await this.loadClients();
      await this.getClient();
    } catch (error) {
      this.$noty.error(`Unable to load client configuration\n ${error.response.data ? JSON.stringify(error.response.data) : ''}`);
    }

    this.loading = false;
  },
  computed: {},
  methods: {
    async loadClients() {
      const clients = await this.$store.dispatch('Core/fetchClients');
      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
    },
    async getClient() {
      const configuration = await this.$store.dispatch('Onboarding/getClient', {
        clientRef: this.client.reference,
      });

      this.referralConfig = this.parseReferralConfig(configuration.client.referral);
    },
    async save() {
      this.saving = true;
      try {
        await this.$store.dispatch('Onboarding/patchClient', {
          clientId: this.client.id,
          data: { referral_configuration: this.referralConfig[0].value },
        });

        this.$noty.success('Client referral configuration saved');
      } catch (error) {
        this.$noty.error(`Unable to save client referral configuration\n ${error.response.data ? JSON.stringify(error.response.data) : ''}`);
      } finally {
        this.saving = false;
      }
    },
    onChange() {
    },
    parseReferralConfig(referralConfig) {
      if (Array.isArray(referralConfig)) {
        const states = referralConfig.map(state => {
          // remove unnecessary rules
          const filteredState = state.value.filter(rule => rule.key === 'state'
            || rule.key === 'code'
            || rule.key === 'pt_provider'
            || rule.key === 'wellness');

          const rules = filteredState.map(rule => {
            if (rule.key === 'pt_provider' || rule.key === 'wellness') {
              return rule;
            }

            // set rules read only
            return { ...rule, options: { read_only: true } };
          });

          return { ...state, value: rules };
        });

        return [ { value: states } ];
      }

      return [ { value: [] } ];
    },
  },
};
</script>

<style scoped>

</style>
